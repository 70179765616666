import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { useState, useEffect, useRef } from 'react';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWithAuth } from '../../../utils/api';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { useSnackbar } from 'notistack';
import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import HelpModal from 'components/HelpModal/HelpModal'

type Model = {
    id: string;
    name: string;
};

type Tool = {
    id: string;
    name: string;
    model_id: string;
};

type UploadedFile = {
    filename: string;
    location: string;
    id: number;
};

const ALLOWED_FILE_TYPES = ['.pdf', '.csv', '.doc', '.docx', '.xls', '.xlsx', '.txt', '.md', '.png', '.jpeg', '.gif', '.webp'];
const MAX_FILE_SIZE_MB = 4;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export function NewChat() {
    const [newMessage, setNewMessage] = useState('');
    const [selectedModel, setSelectedModel] = useState('4');
    const [selectedTool, setSelectedTool] = useState('9');
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const { data: models = [], isLoading: isLoadingModels } = useQuery<Model[]>('models', async () => {
        const api = `${BASE_API_URL}/models`;
        const response = await fetchWithAuth(api);
        if (!response.ok) throw new Error('Failed to fetch models');
        return response.json();
    });

    const { data: tools = [], isLoading: isLoadingTools } = useQuery<Tool[]>('tools', async () => {
        const api = `${BASE_API_URL}/tools`;
        const response = await fetchWithAuth(api);
        if (!response.ok) throw new Error('Failed to fetch tools');
        return response.json();
    });

    const { data: usage = { cost: 0, limit: 10 } } = useQuery<{ cost: number; limit: number }>(['usage']);
    const isUsageExceeded = usage.cost >= usage.limit;

    const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation(
        async () => {
            const api = `${BASE_API_URL}/chat`;
            const newMessageObject = {
                role: 'user' as const,
                text: newMessage,
            };

            const attachment_ids = uploadedFiles.map(file => file.id);

            const response = await fetchWithAuth(api, {
                method: 'POST',
                body: JSON.stringify({
                    messages: [newMessageObject],
                    thread_id: null,
                    model_id: selectedModel,
                    tool_id: selectedTool,
                    attachment_ids: attachment_ids,
                }),
            });

            if (!response.ok) throw new Error('Failed to send message');
            return response.json();
        },
        {
            onSuccess: (result) => {
                const responseThreadId = result.thread_id;
                queryClient.invalidateQueries('threads');
                navigate(`/${responseThreadId}`);

                // Update the usage
                const currentUsage = queryClient.getQueryData<number>(['usage']) ?? 0;
                queryClient.setQueryData(['usage'], +currentUsage + (result.cost ?? 0));
            },
            onError: () => {
                ShowSnack(
                    SnackMessageForm({
                        message: 'Failed to send message',
                    }),
                    { variant: 'error' }
                );
            },
        },
    );

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            
            // Check file sizes before uploading
            const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE_BYTES);
            if (oversizedFiles.length > 0) {
                ShowSnack(
                    SnackMessageForm({
                        message: `Files must be smaller than ${MAX_FILE_SIZE_MB} MB: ${oversizedFiles.map(f => f.name).join(', ')}`,
                    }),
                    { variant: 'error' }
                );
            }
            
            setIsUploading(true);
            const uploadResults = await Promise.all(files.filter(file => file.size < MAX_FILE_SIZE_BYTES).map(file => uploadFile(file)));
            const successfulUploads = uploadResults.filter(result => result !== null) as UploadedFile[];
            setUploadedFiles(prev => [...prev, ...successfulUploads]);
            setIsUploading(false);
        }
        
        // Reset input so the same file can be selected again if needed
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const uploadFile = async (file: File): Promise<UploadedFile | null> => {
        try {
            const response = await fetchWithAuth(`${BASE_API_URL}/files`, {
                method: 'POST',
                body: file,
                headers: {
                    'filename': file.name,
                    'Content-Type': file.type,
                }
            });
            
            if (!response.ok) {
                const errorData = await response.json();
                ShowSnack(SnackMessageForm({ message: errorData.error || 'Failed to upload file' }), { variant: 'error' });
                return null;
            }
            
            const result = await response.json();
            return {
                filename: result.filename,
                location: result.location,
                id: result.id
            };
        } catch (error) {
            ShowSnack(SnackMessageForm({ message: 'Error uploading file' }), { variant: 'error' });
            return null;
        }
    };

    const handleRemoveFile = (file_id: number) => {
        setUploadedFiles(uploadedFiles.filter(file => file.id !== file_id));
    };

    const handlePaste = async (event: React.ClipboardEvent) => {
        const items = Array.from(event.clipboardData.items);
        
        for (const item of items) {
            if (item.type.startsWith('image/')) {
                event.preventDefault();
                const file = item.getAsFile();
                if (file) {
                    setIsUploading(true);
                    const uploadResult = await uploadFile(file);
                    if (uploadResult) {
                        setUploadedFiles(prev => [...prev, uploadResult]);
                    }
                    setIsUploading(false);
                }
                break;
            }
        }
    };

    // Select the preferred model of selected tool.
    useEffect(() => {
        const tool = tools.find(tool => tool.id === selectedTool);
        if (tool && tool.model_id) {
            setSelectedModel(tool.model_id);
        }
    }, [selectedTool, tools]);

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100%'
            sx={{ minHeight: 'calc(100vh - 90px)', backgroundColor: '#f8f8f8', margin: '0 10px' }}>
            <Box sx={{ width: '90%', minHeight: '200px' }}>
                <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='stretch'
                    flexGrow={0}
                    justifyContent='space-between'
                    component='form'
                    onSubmit={() => sendMessage()}
                    gap={1}>
                    <TextField
                        label='Enter your message here (Ctrl+Enter to send)'
                        multiline
                        minRows={5}
                        maxRows={15}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onPaste={handlePaste}
                        disabled={isSendingMessage}
                        onKeyDown={(e) => {
                            if (e.ctrlKey && e.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                        sx={{
                            margin: 0,
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                            '& .MuiInputBase-input': {
                                resize: 'vertical'
                            },
                        }}
                    />
                </Box>
                
                {uploadedFiles.length > 0 && (
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {uploadedFiles.map((file, index) => (
                            <Chip
                                key={`${file.id}-${index}`}
                                label={file.filename}
                                onDelete={() => handleRemoveFile(file.id)}
                                color="primary"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                )}
                
                <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='right'
                    justifyContent='end'
                    gap={1}
                    sx={{ marginTop: '10px' }}>

                    <Box display="flex" alignItems="center" title="Help">
                        <HelpModal buttonFontSize={25}></HelpModal>
                    </Box>
                    
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        accept={ALLOWED_FILE_TYPES.join(',')}
                    />
                    
                    <IconButton 
                        onClick={() => fileInputRef.current?.click()}
                        disabled={isUploading}
                        color="primary"
                        title={`Attach files (${ALLOWED_FILE_TYPES.join(', ')})`}
                    >
                        {isUploading ? <CircularProgress size={24} /> : <AttachFileIcon />}
                    </IconButton>
                        
                    {isLoadingTools ? (
                        <CircularProgress />
                    ) : (
                        <FormControl>
                            <InputLabel id='tool-select-label'>Tool</InputLabel>
                            <Select
                                labelId='tool-select-label'
                                id='tool-select'
                                value={selectedTool}
                                label='Tool'
                                onChange={(e) => setSelectedTool(e.target.value)}>
                                {tools.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {isLoadingModels ? (
                        <CircularProgress />
                    ) : (
                        <FormControl>
                            <InputLabel id='model-select-label'>Model</InputLabel>
                            <Select
                                labelId='model-select-label'
                                id='model-select'
                                value={selectedModel}
                                label='Model'
                                onChange={(e) => setSelectedModel(e.target.value)}>
                                {models.map((m) => (
                                    <MenuItem key={m.id} value={m.id}>
                                        {m.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <Button
                        variant='contained'
                        endIcon={isSendingMessage ? <CircularProgress size={20} color='inherit' /> : <SendIcon />}
                        onClick={() => sendMessage()}
                        disabled={newMessage.length === 0 || isSendingMessage || isUploading || isUsageExceeded}>
                        {isSendingMessage ? 'Sending...' : isUsageExceeded ? 'Send (disabled due to usage limit)' : 'Send'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

