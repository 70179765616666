import Chip from '@mui/material/Chip';
import { useQuery, useQueryClient } from 'react-query';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { fetchWithAuth } from '../../../utils/api';

export function UsageSummary({ labelText }: { labelText: string }) {
    const queryClient = useQueryClient();
    const { data: usage = { cost: 0, limit: 10 }, isLoading: isLoadingUsage } = useQuery<{ cost: number; limit: number }>(['usage'], async () => {
        const api = `${BASE_API_URL}/usage`;
        const response = await fetchWithAuth(api);
        const data = await response.json();
        return {
            cost: +data.cost,
            limit: +data.monthly_usage_limit,
        };
    });

    return (
        <Chip
            label={isLoadingUsage ? 'Loading...' : `${labelText}$${(+usage.cost).toFixed(2)}`}
            variant='outlined'
            color={usage.cost < (usage.limit / 2) ? 'primary' : (usage.cost < usage.limit ? 'warning' : 'error')}
            onClick={() => {
                queryClient.invalidateQueries(['usage']);
            }}
            title='Shows current monthly usage. Click to refresh data.'
        />
    );
}
